.circulation-container {
  margin-bottom: 20px;
}
.circulation-container:last-child {
  margin: unset;
}
.circulation-container p {
  color: #323232;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.circulation-container__caption {
  display: flex;
  justify-content: space-between;
}
.circulation-container__informations {
  display: grid;
  gap: 80px;
  grid-template-columns: repeat(5, 1fr);
  padding-top: 16px;
}
.circulation-container__information {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.circulation-container__information span {
  color: #323232;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.circulation-container__information span:nth-child(2) {
  font-size: 16px;
  font-weight: 500;
}
.circulation-container__information-blue {
  color: #24A3FF !important;
}
.circulation-container__information-red {
  color: #FF0009 !important;
}
.circulation-container__information-blur {
  filter: blur(5px);
}
.circulation-container__buttons a {
  display: inline-block;
  padding: 10px 24px;
  border-radius: 8px;
  background: #FF0009;
  color: #F6F6F6;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.circulation-container__buttons a:hover {
  color: #F6F6F6;
}

@media only screen and (max-width: 991px) {
  .circulation-container__informations {
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
}
@media only screen and (max-width: 575px) {
  .circulation-container__caption {
    flex-direction: column;
  }
  .circulation-container__buttons {
    align-self: center;
  }
}

