.circulation-container {
  margin-bottom: 20px;

  &:last-child {
    margin: unset;
  }

  p {
    color: #323232;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  &__caption {
    display: flex;
    justify-content: space-between;
  }

  &__informations {
    display: grid;
    gap: 80px;
    grid-template-columns: repeat(5, 1fr);
    padding-top: 16px;
  }

  &__information {
    display: flex;
    flex-direction: column;
    gap: 8px;

    span {
      color: #323232;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      &:nth-child(2) {
        font-size: 16px;
        font-weight: 500;
      }
    }

    &-blue {
      color: #24A3FF !important;
    }

    &-red {
      color: #FF0009 !important;
    }

    &-blur {
      filter: blur(5px);
    }
  }

  &__buttons {
    a {
      display: inline-block;
      padding: 10px 24px;
      border-radius: 8px;
      background: #FF0009;
      color: #F6F6F6;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      &:hover {
        color: #F6F6F6;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .circulation-container {
    &__informations {
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
    }
  }
}

@media only screen and (max-width: 575px) {
  .circulation-container {
    &__caption {
      flex-direction: column;
    }

    &__buttons {
      align-self: center;
    }
  }
}